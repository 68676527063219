@media (max-width: 419px) {
  .content {
    padding: 1.5rem;
  }
}

@media (max-width: 959px) {
  .content {
    padding: 2rem;
  }
}

.page {
  padding-bottom: 2rem;
  display: block;
  &.has-sidebar {
    padding-left: 0;
  }
  .content {
    max-width: 740px;
    margin: 0 auto;
    padding: 4rem 2.5rem;
  }
}

@media (min-width: 720px) {
  .page.has-sidebar {
    padding-left: 16.4rem;
  }
}
