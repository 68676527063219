.navbar {
  z-index: 20;
  right: 0;
  height: 3.6rem;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
}

.navbar,
.sidebar-mask {
  position: fixed;
  top: 0;
  left: 0;
}

.navbar {
  z-index: 20;
  right: 0;
  height: 3.6rem;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
}

.navbar .links {
  padding-left: 1.5rem;
  box-sizing: border-box;
  background-color: #fff;
  white-space: nowrap;
  font-size: 0.9rem;
  position: absolute;
  right: 1.5rem;
  top: 0.7rem;
  display: flex;
}

.nav-links {
  display: inline-block;
}

.nav-links .nav-item.depth-1 {
  margin-left: 0;
  font-size: 1.1em;
  font-weight: 700;
}
.nav-separator {
  border-bottom: 1px solid #eaecef;
  margin: 0.5rem;
  width: 20rem;
}

.nav-links .nav-item {
  position: relative;
  display: inline-block;
  margin-left: 1.5rem;
  line-height: 2rem;
}

.navbar a,
.navbar img,
.navbar span {
  display: inline-block;
}

.navbar a,
.navbar img,
.navbar span {
  display: inline-block;
}

.navbar {
  padding: 0.7rem 1.5rem;
  line-height: 2.2rem;
}

.nav-links a {
  line-height: 1.4rem;
  color: inherit;
}

a,
p a code {
  color: #3eaf7c;
}

a {
  font-weight: 500;
  text-decoration: none;
}

.site-name {
  font-size: 1.3rem;
  font-weight: 600;
  color: #2c3e50;
  position: relative;
}

.nav-item > a:not(.external):hover {
  margin-bottom: -2px;
  border-bottom: 2px solid #46bd87;
}

.sidebar-button {
  cursor: pointer;
  display: none;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  padding: 0.6rem;
  top: 0.6rem;
  left: 1rem;
}

@media (max-width: 719px) {
  .navbar .site-name {
    width: calc(100vw - 6.4rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 719px) {
  .sidebar-button {
    display: block;
  }
}

@media (max-width: 719px) {
  .navbar .can-hide {
    display: none;
  }
}

@media (max-width: 719px) {
  .navbar {
    padding-left: 4rem;
  }
}

.sidebar .nav-links {
  border-bottom: 1px solid #eaecef;
  padding: 0.5rem 0 0.75rem;
}

.sidebar {
  display: none;
  font-size: 16px;
  background-color: #fff;
  width: 20rem;
  position: fixed;
  z-index: 10;
  margin: 0;
  top: 3.6rem;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  border-right: 1px solid #eaecef;
  overflow-y: auto;
  &.has-sidebar {
    display: inherit;
  }
}

.sidebar .nav-links .nav-item,
.sidebar .nav-links .repo-link {
  display: block;
  line-height: 1.25rem;
  font-size: 1.1em;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

@media (max-width: 719px) {
  .sidebar.mobile-sidebar-open {
    transform: translateX(0);
    display: inherit;
  }
  .sidebar {
    top: 0;
    padding-top: 3.6rem;
    transform: translateX(-100%);
    transition: transform 0.2s ease;
  }
}
@media (max-width: 959px) {
  .sidebar {
    font-size: 15px;
    width: 16.4rem;
  }
}

@media (max-width: 719px) {
  .nav-links .nav-item,
  .nav-links .repo-link {
    margin-left: 0;
  }
}

@media (min-width: 720px) {
  .sidebar .nav-links .nav-item.sidebar-nav,
  .sidebar-nav {
    display: none;
  }
}
